
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Buyer, BankTransfer } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'TopupBuyerDialog'
})
export default class extends Vue {
  @Prop({ required: true }) private buyer!: Buyer | null

  private tempTopup: BankTransfer = new BankTransfer({ manageable: new Buyer({ id: '' })})
  private loading = false

  rules: any = {
    amount: [
      { required: true, message: this.$t('topupBuyerDialog.rules.amount'), trigger: 'blur' }
    ],
    reference: [
      { required: true, message: this.$t('topupBuyerDialog.rules.reference'), trigger: 'blur' }
    ],
    sendAt: [
      { required: true, message: this.$t('topupBuyerDialog.rules.sendAt'), trigger: 'blur' }
    ]
  }

  created(): void {
    this.setTempData()
  }

  createOrUpdate() {
    (this.$refs.topupBuyer as Form).validate(async(valid) => {
      this.loading = true
      if (valid) {
        const data = this.tempTopup

        try {
          await data.save({ with: 'manageable.id' })

          this.$emit('created', data.dup())
          this.$notify({
            title: this.$t('topupBuyerDialog.notifications.success.title') as string,
            message: `${this.buyer?.name} ${this.$t('topupBuyerDialog.notifications.success.subtitle')} ${this.$n(data.amount, 'currency')}`,
            type: 'success',
            duration: 2000
          })
        } catch (err) {
          this.$notify({
            title: this.$t('topupBuyerDialog.notifications.error.title') as string,
            message: this.$t('topupBuyerDialog.notifications.error.subtitle') as string,
            type: 'error',
            duration: 2000
          })
          this.loading = false
        }
      }
      this.loading = false
    })
  }

  setTempData() {
    this.tempTopup = new BankTransfer({ manageable: this.buyer?.dup() })
  }
}
