
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Program } from '@/models'
import { Form as ElForm } from 'element-ui/types/element-ui'

@Component({
  name: 'DialogManageDomain'
})
export default class extends Vue {
  @Prop({ required: true }) program!: Program
  @Prop({ required: true }) loading!: boolean

  tempProgram: Program = new Program()
  popoverVisible = false
  domainForm = {
    domain: ''
  }
  rules: any = {
    domain: [
      { required: true, message: this.$t('manageDomainDialog.popoverAdd.rules.domainRequired'), trigger: 'blur' },
      { pattern: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: this.$t('manageDomainDialog.popoverAdd.rules.domainInvalid'), trigger: 'blur' }
    ]
  }

  async addDomain(): Promise<void> {
    (this.$refs.domainForm as ElForm).validate(async(valid) => {
      if (!valid) return

      Object.assign(this.tempProgram, JSON.parse(JSON.stringify(this.program)))

      this.tempProgram.domainWhitelist.unshift(this.domainForm.domain)
      this.$emit('updateProgram', this.tempProgram)
    })
  }

  resetValidation(): void {
    this.popoverVisible = false
    ;(this.$refs.domainForm as ElForm).resetFields()
    this.domainForm.domain = ''
  }
}
