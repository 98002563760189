
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Program } from '@/models'

@Component({
  name: 'DomainCard'
})
export default class extends Vue {
  @Prop({ required: true }) program!: Program
  @Prop({ required: true }) loading!: boolean
  @Prop({ required: true }) domain!: string

  tempProgram: Program = new Program()

  async deleteDomain(domain: string): Promise<void> {
    Object.assign(this.tempProgram, JSON.parse(JSON.stringify(this.program)))

    const domainIndex = this.tempProgram.domainWhitelist.findIndex((d: any) => d === domain)
    if (domainIndex !== -1) this.tempProgram.domainWhitelist.splice(domainIndex, 1)

    this.$emit('updateProgram', this.tempProgram)
  }
}
