
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Account, Buyer, Location, Manageable } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'CreateBuyerDialog',
  components: {
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private buyer!: Buyer | null

  tempData: Buyer = new Buyer({ location: new Location() })
  tempAccount: Account = new Account({ manageable: new Manageable() })

  private loading = false
  private createBuyerSuccess = false
  private createUser = false

  private dialogStatus = 'create'

  rulesBuyer: any = {
    name: [
      { required: true, message: this.$t('createBuyerDialog.rules.name'), trigger: 'blur' }
    ],
    code: [
      { required: true, message: this.$t('createBuyerDialog.rules.code'), trigger: 'blur' }
    ],
    intraVatNumber: [
      { required: true, message: this.$t('createBuyerDialog.rules.intraVatNumber'), trigger: 'blur' }
    ],
    'location.name': [
      { required: true, message: this.$t('createBuyerDialog.rules.locationName'), trigger: 'blur' }
    ],
    'location.line1': [
      { required: true, message: this.$t('createBuyerDialog.rules.locationLine1'), trigger: 'blur' }
    ],
    'location.zipcode': [
      { required: true, message: this.$t('createBuyerDialog.rules.locationZipCode'), trigger: 'blur' }
    ],
    'location.city': [
      { required: true, message: this.$t('createBuyerDialog.rules.locationCity'), trigger: 'blur' }
    ],
    'location.state': [
      { required: true, message: this.$t('createBuyerDialog.rules.locationState'), trigger: 'blur' }
    ],
  }

  rulesAccount: any = {
    firstname: [
      { required: true, message: this.$t('createBuyerDialog.rules.firstname'), trigger: 'blur' }
    ],
    lastname: [
      { required: true, message: this.$t('createBuyerDialog.rules.lastname'), trigger: 'blur' }
    ],
    email: [
      { required: true, message: this.$t('createBuyerDialog.rules.email'), trigger: 'blur' }
    ],
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      !this.buyer ? this.handleCreate() : this.handleUpdate()
    }
  }

  private createOrUpdateBuyer() {
    (this.$refs.buyerForm as Form).validate(async(valid) => {
      this.loading = true
      if (valid) {
        try {
          await this.tempData.save({ with: 'location' })

          if (this.dialogStatus === 'create') {
            this.$emit('created', this.tempData.dup())

            this.$notify({
              title: this.$t('createBuyerDialog.notifications.success.title') as string,
              message: this.$t('createBuyerDialog.notifications.success.subtitle') as string,
              type: 'success',
              duration: 2000
            })

            if (this.createUser) {
              this.createBuyerSuccess = true
              this.createAccount()
            } else this.$emit('close')
          } else {
            this.$emit('updated', this.tempData.dup())

            this.$notify({
              title: this.$t('createBuyerDialog.notifications.successUpdate.title') as string,
              message: this.$t('createBuyerDialog.notifications.successUpdate.subtitle') as string,
              type: 'success',
              duration: 2000
            })
            this.$emit('close')
          }
        } catch (err) {
          this.$notify({
            title: this.$t('createBuyerDialog.notifications.error.title') as string,
            message: this.$t('createBuyerDialog.notifications.error.subtitle') as string,
            type: 'error',
            duration: 2000
          })
          this.loading = false
        }
      }
      this.loading = false
    })
  }

  private createAccount(): void {
    (this.$refs.createAccountForm as Form).validate(async(valid) => {
      if (valid) {
        try {
          this.tempAccount.manageable = this.tempData.dup()
          await this.tempAccount.save({ with: 'manageable.id' })

          this.$notify({
            title: this.$t('createBuyerDialog.notifications.successAccount.title') as string,
            message: this.$t('createBuyerDialog.notifications.successAcount.subtitle') as string,
            type: 'success',
            duration: 2000
          })
          this.$emit('close')
        } catch (err) {
          this.$notify({
            title: this.$t('createBuyerDialog.notifications.errorAccount.title') as string,
            message: this.$t('createBuyerDialog.notifications.errorAccount.subtitle') as string,
            type: 'error',
            duration: 2000
          })
          this.loading = false
        }
      }
      this.loading = false
    })
    this.loading = false
  }

  private uploadCallback(file: any) {
    this.tempData.logo = file.blob.signed_id
  }

  handleCreate() {
    this.tempData = new Buyer({ location: new Location() })
    this.tempAccount = new Account({ manageable: new Manageable() })

    this.$nextTick(() => {
      (this.$refs.buyerForm as Form).clearValidate()
    })
  }

  private handleUpdate() {
    this.tempData = this.buyer!.dup()
    if (!this.tempData.location) this.tempData.location = new Location()

    this.dialogStatus = 'update'
    this.$nextTick(() => {
      (this.$refs.buyerForm as Form).clearValidate()
    })
  }
}
