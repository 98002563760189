
import { Component, Vue } from 'vue-property-decorator'
import { Program } from '@/models'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'FiltersDrawer'
})
export default class extends Vue {
  userStore: any = useUserStore()

  showFilters = false

  programs: Program[] = []
  programsLoading = false

  listQuery: any = {
    sortByDate: 'desc',
    sortByUpdate: null,
    sortByName: null,
    page: 1,
    firstName: null,
    lastName: null,
    email: null,
    businessEmail: null,
    confirmed: null,
    approval: null,
    registrationFailureReason: null,
    dateRange: null,
    programId: null
  }

  sortByDateOptions = [
    {
      label: this.$t('customers.header.filters.sortByDateOptions.desc'),
      value: 'desc'
    },
    {
      label: this.$t('customers.header.filters.sortByDateOptions.asc'),
      value: 'asc'
    }
  ]

  sortByUpdateOptions = [
    {
      label: this.$t('customers.header.filters.sortByUpdateOptions.desc'),
      value: 'desc'
    },
    {
      label: this.$t('customers.header.filters.sortByUpdateOptions.asc'),
      value: 'asc'
    }
  ]

  sortByNameOptions = [
    {
      label: this.$t('customers.header.filters.sortByNameOptions.asc'),
      value: 'asc'
    },
    {
      label: this.$t('customers.header.filters.sortByNameOptions.desc'),
      value: 'desc'
    }
  ]

  confirmedOptions = [
    {
      label: this.$t('customers.header.filters.confirmedOptions.notConfirmed'),
      value: false
    },
    {
      label: this.$t('customers.header.filters.confirmedOptions.confirmed'),
      value: true
    }
  ]

  approvalOptions = [
    {
      label: this.$t('customers.header.filters.approvalOptions.waiting'),
      value: 'waiting'
    },
    {
      label: this.$t('customers.header.filters.approvalOptions.verification_failed'),
      value: 'verification_failed'
    },
    {
      label: this.$t('customers.header.filters.approvalOptions.verified'),
      value: 'verified'
    }
  ]

  verificationFailedOptions = [
    {
      label: this.$t('customers.header.filters.registrationFailureReasonOptions.no_reason'),
      value: 'no_reason'
    },
    {
      label: this.$t('customers.header.filters.registrationFailureReasonOptions.bad_email'),
      value: 'bad_email'
    },
    {
      label: this.$t('customers.header.filters.registrationFailureReasonOptions.domain_unknown'),
      value: 'domain_unknown'
    },
    {
      label: this.$t('customers.header.filters.registrationFailureReasonOptions.catch_all_domain'),
      value: 'catch_all_domain'
    },
    {
      label: this.$t('customers.header.filters.registrationFailureReasonOptions.unknown_email'),
      value: 'unknown_email'
    },
    {
      label: this.$t('customers.header.filters.registrationFailureReasonOptions.risky_email'),
      value: 'risky_email'
    },
    {
      label: this.$t('customers.header.filters.registrationFailureReasonOptions.mailgun_failed'),
      value: 'mailgun_failed'
    }
  ]

  get isCaretakerIEG(): boolean {
    return this.userStore.isCaretakerIEG
  }

  get filtersActive() {
    return Object.keys(this.listQuery).filter(i => {
      if (this.listQuery[i] !== null && this.listQuery[i] !== '') {
        if ((typeof this.listQuery[i] === 'object' && this.listQuery[i].length > 0) || typeof this.listQuery[i] !== 'object') {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  private created() {
    this.getPrograms()
  }

  changeSort(sortBy: string) {
    if (sortBy === 'date') {
      this.listQuery.sortByName = null
      this.listQuery.sortByUpdate = null
    } else if (sortBy === 'update') {
      this.listQuery.sortByName = null
      this.listQuery.sortByDate = null
    } else {
      this.listQuery.sortByUpdate = null
      this.listQuery.sortBydate = null
    }
  }

  private async getPrograms() {
    this.programsLoading = true

    const { data } = await Program
      .page(1)
      .per(100)
      .all()

    this.programs = data
    this.programsLoading = false
  }

  resetFilters() {
    this.listQuery = {
      sortByDate: 'desc',
      sortByUpdate: null,
      sortByName: null,
      page: 1,
      firstName: null,
      lastName: null,
      email: null,
      businessEmail: null,
      confirmed: null,
      approval: null,
      registrationFailureReason: null,
      dateRange: null,
      programId: null
    }

    this.refresh()
  }

  refresh() {
    this.showFilters = false
    this.$emit('applyFilters', this.listQuery)
  }
}
