
import { Component, Vue } from 'vue-property-decorator'
import { Customer } from '@/models'

@Component({
  name: 'CustomersHeader'
})
export default class extends Vue {
  private totalCustomers = 0

  async created(): Promise<void> {
    await this.getCustomers()
  }

  private async getCustomers(): Promise<void> {
    const { meta } = await Customer.stats({ total: 'count' }).all()
    this.totalCustomers = meta.stats.total.count
  }
}
