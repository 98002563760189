
import { Component, Vue } from 'vue-property-decorator'
import { Customer } from '@/models'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'Customers',
  components: {
    CustomersHeader: () => import('./components/CustomersHeader.vue'),
    CustomerCard: () => import('./components/CustomerCard.vue'),
    DialogCustomer: () => import('./components/DialogCustomer.vue'),
    FilterDrawer: () => import('./components/FiltersDrawer.vue'),
    DialogManageDomain: () => import('./components/DialogManageDomain.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()

  customers: Customer[] = []
  totalCustomers = 0
  customersLoading = true

  tempCustomer: Customer | null = null
  visibleDialog = false

  listQuery: any = {
    sortByDate: 'desc',
    sortByUpdate: null,
    sortByName: null,
    page: 1,
    firstName: null,
    lastName: null,
    email: null,
    businessEmail: null,
    confirmed: null,
    approval: null,
    registrationFailureReason: null,
    dateRange: null,
    programId: null
  }

  get isCaretakerIEG(): boolean {
    return this.userStore.isCaretakerIEG
  }

  created(): void {
    this.getCustomers()
  }

  async getCustomers(): Promise<void> {
    this.customersLoading = true

    const createdAtGte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange![0])).format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange![1])).format('YYYY-MM-DD HH:mm:ss') : ''
    const sort = this.listQuery.sortByName ? { lastname: this.listQuery.sortByName } : this.listQuery.sortByUpdate ? { updatedAt: this.listQuery.sortByUpdate } : { createdAt: this.listQuery.sortByDate }

    const { data, meta } =
      await Customer
        .includes(['program'])
        .where({ firstname: { prefix: this.listQuery.firstName } })
        .where({ lastname: { prefix: this.listQuery.lastName } })
        .where({ email: { prefix: this.listQuery.email } })
        .where({ businessEmail: { prefix: this.listQuery.businessEmail } })
        .where({ isConfirmed: this.listQuery.confirmed })
        .where({ registrationStatus: this.listQuery.approval })
        .where({ registrationFailureReason: this.listQuery.registrationFailureReason })
        .where({ createdAt: { gte: createdAtGte, lte: createdAtLte } })
        .where({ programId: this.listQuery.programId })
        .page(this.listQuery.page)
        .per(20)
        .order(sort)
        .stats({ total: 'count' })
        .all()

    this.customers.push(...data)
    this.totalCustomers = meta.stats.total.count
    this.listQuery.page += 1
    this.customersLoading = false
  }

  setCustomer(customer: Customer): void {
    this.customers.unshift(customer)
    this.totalCustomers += 1
  }

  updateCustomer(customer: Customer): void {
    const index = this.customers.findIndex((c) => c.id === customer.id)
    this.customers.splice(index, 1, customer)
  }

  async getCustomer(customerId: string): Promise<void> {
    const { data } = await Customer.includes(['program']).find(customerId)

    if (data) {
      const index = this.customers.findIndex((c) => c.id === data.id)
      this.customers.splice(index, 1, data)
    }
  }

  deleteCustomer(customer: Customer): void {
    this.customers.splice(this.customers.findIndex((c: Customer) => c.id === customer.id), 1)
  }

  resetFilters(): void {
    ;(this.$refs.headerFilters as any).resetFilters()
  }

  setFilters(filters: any): void {
    this.listQuery = filters || this.listQuery
    this.listQuery.page = 1
    this.totalCustomers = 0
    this.customers.splice(0, this.customers.length)

    this.getCustomers()
  }
}
