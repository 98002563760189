
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Buyer, Location } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'EditBuyerFormCard',
  components: {
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) buyer!: Buyer

  private activeTab = 'first'
  private tempBuyer: Buyer = new Buyer()
  private updateLoading = false

  @Watch('buyer', { immediate: true, deep: true })
  setTempBuyer() {
    this.tempBuyer = this.buyer.dup()
    if (!this.tempBuyer.location) this.tempBuyer.location = new Location()
  }

  private updateBuyer() {
    (this.$refs.buyerForm as Form).validate(async(valid) => {
      this.updateLoading = true
      if (valid) {
        try {
          await this.tempBuyer.save({ with: 'location' })

          this.$notify({
            title: this.$t('createBuyerDialog.notifications.successUpdate.title') as string,
            message: this.$t('createBuyerDialog.notifications.successUpdate.subtitle') as string,
            type: 'success',
            duration: 2000
          })
          this.$emit('update', this.tempBuyer.dup())
        } catch (err) {
          this.$notify({
            title: this.$t('createBuyerDialog.notifications.error.title') as string,
            message: this.$t('createBuyerDialog.notifications.error.subtitle') as string,
            type: 'error',
            duration: 2000
          })
          this.updateLoading = false
        }
      }
      this.updateLoading = false
    })
  }

  private uploadCallback(file: any) {
    this.tempBuyer.logo = file.blob.signed_id
  }
}
