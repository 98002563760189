
import { Component, Vue } from 'vue-property-decorator'
import { Program } from '@/models'

@Component({
  name: 'DialogManageDomain',
  components:{
    DomainCard: () => import('./DomainCard.vue'),
    PopoverAddDomain: () => import('./PopoverAddDomain.vue')
  }
})
export default class extends Vue {
  program: Program = new Program()
  programs: Program[] = []

  searchDomain = ''
  visible = false
  loading = false

  get filteredDomains(): string[] {
    return this.program.domainWhitelist?.filter(d => d.startsWith(this.searchDomain)) || []
  }

  created(): void {
    this.getPrograms()
  }

  private async getPrograms(): Promise<void> {
    const { data } = await Program.all()

    this.programs = data
    if (this.programs.length > 0) this.program = this.programs[0]
  }

  public async updateProgram(program: Program): Promise<void> {
    this.loading = true

    try {
      await program.save()

      this.program = program.dup()

      ;(this.$refs.popoverAddDomain as any).resetValidation()
      this.$notify({
        title: this.$t('manageDomainDialog.notifications.success.title') as string,
        message: this.$t('manageDomainDialog.notifications.success.message') as string,
        type: 'success',
        duration: 2000
      })
    } catch (error) {
      this.$notify({
        title: this.$t('manageDomainDialog.notifications.error.title') as string,
        message: this.$t('manageDomainDialog.notifications.error.message') as string,
        type: 'error',
        duration: 2000
      })
    }

    this.loading = false
  }

  setVisibility(visible: boolean): void {
    this.visible = visible
  }

  setProgram(programId: string): void {
    const program = this.programs.find(p => p.id === programId)
    if (program) this.program = program
  }
}
