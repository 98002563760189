
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Buyer } from '@/models'

@Component({
  name: 'BuyerCard'
})
export default class extends Vue {
  @Prop({ required: true }) private buyer!: Buyer
}
