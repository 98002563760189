
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Customer } from '@/models'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'CustomerCard',
  components: {
    PopoverVerifyCustomer: () => import('./PopoverVerifyCustomer.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) customer!: Customer

  userStore: any = useUserStore()

  loadingDelete = false

  get isCaretakerIEG(): boolean {
    return this.userStore.isCaretakerIEG
  }

  async deleteCustomer(customer: Customer): Promise<void> {
    this.loadingDelete = true
    try {
      await customer.destroy()

      this.$notify({
        title: this.$t('customers.notification.delete.success.title') as string,
        message: this.$t('customers.notification.delete.success.subtitle') as string,
        type: 'success',
        duration: 2000
      })
      this.$emit('delete', customer)
    } catch (e) {
      this.$notify({
        title: this.$t('customers.notification.delete.error.title') as string,
        message: this.$t('customers.notification.delete.error.subtitle') as string,
        type: 'error',
        duration: 2000
      })
      this.loadingDelete = false
    }
    this.loadingDelete = false
  }
}
